import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';

const CopyrightLinks = () => {
  return (
    <>
      <StyledDivider>|</StyledDivider>
      <StyledLink to="/privacy">隱私政策</StyledLink>
      <StyledDivider>|</StyledDivider>
      <StyledLink to="/terms">服務聲明</StyledLink>
    </>
  );
};

const StyledLink = styled(Link)`
  font-size: 12px;
  color: white;
  textdecoration: none;
`;
const StyledDivider = styled.span`
  display: inline-block;
  padding: 0 10px;
  font-size: 12px;
  color: white;
`;

export default CopyrightLinks;

import React, {useState, useContext, useLayoutEffect} from 'react';
import styled from 'styled-components';
import {ShoppingBag, Bell} from '@styled-icons/feather';
import {Close, Menu} from '@styled-icons/material';
import {Link as GatsbyLink} from 'gatsby';
import {Context} from '../../AppContext';
import Image from '../Image';
import {Badge, Link as WidgetLink} from '../../Widgets';
import useNotification from '../../hooks/use-notification';
import Expander from './Expander';
import useDimension from '../../hooks/use-dimension';
const appConfig = require('../../data.json');

const config = require('../../data.json');

const EXPANDERS = {
  menu: 'menu',
  categories: 'categories',
  notifications: 'notifications',
};

const navItems = [
  {
    children: '全部商品',
    url: '/products',
  },
  {children: config.mapping.about, url: '/about'},
  {children: config.mapping.articles, url: '/articles'},
  {children: config.mapping.faq, url: '/faq'},
];

export default function Navbar(props) {
  const {pathname = ''} = props;
  const app = useContext(Context);
  const [expanded, setExpanded] = useState(null);
  const {cart} = app.state;
  const {count} = useNotification();
  const {dimension} = useDimension();
  const [transparant, setTransparent] = useState(true);

  useLayoutEffect(() => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      function onScroll() {
        if (
          document.body.scrollTop >= 60 ||
          document.documentElement.scrollTop >= 60
        ) {
          setTransparent(false);
        } else {
          setTransparent(true);
        }
      }

      window.addEventListener('scroll', onScroll);

      return () => window.removeEventListener('scroll', onScroll);
    }
  }, []);

  function isActice(url) {
    return (
      pathname.replace('/', '').slice(0, 3) === url.replace('/', '').slice(0, 3)
    );
  }

  const expand = (expd = null) => {
    setExpanded(expanded ? null : expd);
  };

  return (
    <>
      {appConfig.navbarHeightExcludePages.includes(pathname) ? null : (
        <Placeholder />
      )}

      <Style.Wrapper transparant={transparant}>
        <Style.Center>
          <GatsbyLink to="/" aria-label="home">
            <Logo 
              src="/images/logo.svg"
              height={config.navbarLogoHeight}
              width="auto"
              alt="首頁"
            />
          </GatsbyLink>
          <div style={{flex: 1}} />

          {dimension.innerWidth >= config.breakpoints.lg && (
            <Style.Row>
              {navItems.map(({children, url}, idx) => {
                let extraProps = {
                  to: url,
                };

                if (url === '/products') {
                  extraProps.to = '#';
                  extraProps.onClick = (e) => {
                    e.preventDefault();
                    expand(EXPANDERS.categories);
                  };
                }

                return (
                  <ItemWrapper key={idx}>
                    <Link
                      className={`item ${isActice(url) ? 'active' : ''}`}
                      aria-label="item"
                      {...extraProps}>
                      {children}
                    </Link>

                    {url === '/products' && (
                      <Expander
                        current={expanded}
                        name={EXPANDERS.categories}
                        onClose={expand}
                      />
                    )}
                  </ItemWrapper>
                );
              })}
            </Style.Row>
          )}

          <div style={{flex: 1}} />

          <Style.Row>
            {/* CART   */}
            <ItemWrapper>
              <Link to="/cart" className="icon" aria-label="cart">
                <Badge
                  count={cart ? cart.items.length : 0}
                  borderColor={config.navbarBackgroundColor}>
                  <ShoppingBag size={25} color={config.navbarIconColor} />
                </Badge>
              </Link>
            </ItemWrapper>

            {/* NOTIFY */}
            <ItemWrapper>
              <Link
                className="icon"
                aria-label="notify"
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  if (count > 0) {
                    expand(EXPANDERS.notifications);
                  }
                }}>
                <Badge count={count} borderColor={config.navbarBackgroundColor}>
                  <Bell size={25} color={config.navbarIconColor} />
                </Badge>
              </Link>
              <Expander
                current={expanded}
                name={EXPANDERS.notifications}
                onClose={expand}
              />
            </ItemWrapper>

            {/* PROFILE */}
            <ItemWrapper>
              <Link to="/orders" className="icon" aria-label="orders">
                <Image
                  src="/images/profile-icon.svg"
                  width="25"
                  height="25"
                  alt="會員中心"
                />
              </Link>
            </ItemWrapper>

            {/* MENU */}
            {dimension.innerWidth < config.breakpoints.lg && (
              <ItemWrapper>
                <Link
                  className="icon"
                  aria-label="menu"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    expand(EXPANDERS.menu);
                  }}>
                  {expanded === EXPANDERS.menu ? (
                    <Close size={25} color={config.colors.main} />
                  ) : (
                    <Menu size={25} color={config.colors.main} />
                  )}
                </Link>
                <Expander
                  current={expanded}
                  name={EXPANDERS.menu}
                  onClose={expand}
                />
              </ItemWrapper>
            )}
          </Style.Row>
        </Style.Center>
      </Style.Wrapper>
    </>
  );
}

const Placeholder = styled.div`
  height: ${config.navbarHeight}px;
  @media screen and (max-width: ${config.breakpoints.sm}px) {
    height: ${config.navbarHeightMore}px;
  }
`;

const Style = {
  Wrapper: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: ${config.navbarHeight}px;
    z-index: 10;
    background: ${(props) =>
      props.transparant ? 'transparent' : config.navbarBackgroundColor};
    transition: background 0.3s ease-in;
    box-shadow: ${(props) =>
      props.transparant ? 'none' : '0 3px 6px 0 rgba(0, 0, 0, 0.16)'};

    display: flex;
    align-items: center;

    @media screen and (max-width: ${config.breakpoints.sm}px) {
      height: ${config.navbarHeightMore}px;
    }
  `,

  Center: styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0px 0px;
    max-width: ${config.maxContentWidth}px;
    margin: 0 auto;
    height: 100%;

    @media screen and (max-width: ${config.maxContentWidth}px) {
      padding: 0px 20px;
    }
  `,

  Row: styled.div`
    display: flex;
    align-items: stretch;
    align-self: stretch;
  `,
};

const ItemWrapper = styled.div`
  align-self: stretch;
  display: flex;
  align-items: stretch;
  cursor: pointer;
  position: relative;
`;

const Link = styled(WidgetLink)`
  display: flex;
  align-items: center;
  color: ${config.colors.text};

  &.item {
    margin: 0 20px;

    &:active {
      border-bottom: solid 2px ${config.colors.main};
      color: ${config.colors.main};
    }

    &.active {
      border-bottom: solid 2px ${config.colors.main};
      color: ${config.colors.main};
    }
  }

  &.icon {
    margin-left: 20px;
  }

  &:hover {
    opacity: 1;
  }
`;

const Logo = styled(Image)`
  @media screen and (max-width: ${config.breakpoints.sm}px) {
    width: 100%;
  }
`
module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"建鏵國際資訊有限公司","short_name":"建鏵數位印刷","start_url":"/","background_color":"#0A75B7","theme_color":"#0A75B7","display":"minimal-ui","icon":"static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6b0cdc3ad048e0b8edf7a62a3833713b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"631314008047651"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
